import * as React from 'react';
import { graphql } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';

const defaultDescription = 'Edit this in your entry for a better SEO';
const defaultTitle = 'Olokoo Q2M';

function OfferingTemplate({ data }) {
  const models = data?.allBuilderModels;
  const offering = models.offering[0]?.content;
  const settings = models?.oneSiteSettings?.data;

  const title = offering?.data?.title || settings?.siteName || defaultTitle;
  const description = offering?.data?.description || settings?.siteDescription || defaultDescription;
  const url = settings?.siteUrl + offering?.data?.url;

  return (
    <>
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={ description } />
        <link rel="icon" type="image/png" href={ settings?.siteFavicon } sizes="16x16" />
        
        <meta property="og:title" content={ title } />
        <meta property="og:description" content={ description } />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ url } />
        <meta property="og:image" content={ settings?.siteSocialImage } />

        <meta name="twitter:title" content={ title } />
        <meta name="twitter:description" content={ description } />
        <meta name="twitter:image" content={ settings?.siteSocialImage } />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BuilderComponent
        renderLink={Link}
        name="offering"
        content={offering}
      />
    </>
  );
}

export default OfferingTemplate;

export const offeringQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      offering(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
      oneSiteSettings {
        data {
          siteName
          siteDescription
          siteFavicon
          siteSocialImage
          siteUrl
        }
      }
    }
  }
`;
